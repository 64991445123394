import Input from "@/components/input";
import { uuid } from "vue-uuid";
import { FETCH_FIELDS } from "@/modules/fields-admin/store";
import axios from "@/axios";
import loader from "@/components/loader";
// import modalConfirm from "@/components/
const modalConfirm = () => import("@/components/modal-confirm");
import modalContent from "@/components/modal-content";
const Button = () => import("@/components/button");
import VueMultiselect from "vue-multiselect";
import "vue-multiselect/dist/vue-multiselect.min.css";
// import alert from "@/components/alert-box";
import LoaderFull from "@/components/loader-full";
import deleteFieldModal from "./../components/deleteFieldModal";
import { labelInfoRichText } from "../fieldImports";
const exclude_field_to_show_placeholder = ["label info rich text", "Label", "Radio", "Radio group"];
export default {
    name: "field-table-data-view",
    components: {
        deleteFieldModal,
        "neo-input": Input,
        // tippy: TippyComponent,
        VueMultiselect,
        modalConfirm,
        loader,
        // alert,
        Button,
        modalContent,
        "loader-full": LoaderFull,
        labelInfoRichText,
    },
    props: {},
    data: () => ({
        deleteFieldModalOpen: false,
        loading: false,
        fields: [],
        fieldLists: [],
        fieldData: {
            use_in_gap_validation: false,
            name: "",
            description: "",
            field_type: "",
            placeholder: "",
            label: "",
            is_cascaded: false,
            tenant_id: "",
            options: [],
            href: "",
        },
        activeOptionInMutation: null,
        dependentFields: [],
        fieldOptions: [],
        fieldTypeIcon: null,
        error: null,
        isLoading: false,
        showDeleteIcon: false,
        optionsLoading: false,
        errorCount: 0,
        showCascadedField: false,
        currentField: null,
        fieldIsCascaded: false,
        isEditLoading: false,
        usedInDetails: [],
        isDeleteLoading: false,
    }),

    computed: {
        getSelectedField() {
            return this.fieldOptions?.[this.activeOptionInMutation];
        },

        getDependentFields() {
            // return this.getSelectedField?.dependents || [];
            // TODO remove:
            // temporary fix for not displayed names:
            return (this.getSelectedField?.dependents || []).map((el) => ({
                ...el,
                name: el.name || el.label,
            }));
        },

        orderedDependentFields() {
            return this.dependentFields.sort((a, b) => (a.order > b.order ? 1 : -1));
        },
        showPlaceholder() {
            return !exclude_field_to_show_placeholder.includes(this.fieldData && this.fieldData.type);
        },
    },
    //   created() {
    //     // watch the params of the route to fetch the data again
    //     this.$watch(
    //       () => this.$route.params,
    //       () => {
    //         // this.fetchData();
    //       },
    //       // fetch the data when the view is created and the data is
    //       // already being observed
    //       { immediate: true }
    //     );
    //   },
    async mounted() {
        if (!this.$store.getters.fieldsType.length) {
            await this.$store.dispatch(FETCH_FIELDS);
        }
        this.loading = true;
        this.fields = this.$store.getters.fieldsType;
        if (!this.$store.getters.getTenantId) await this.$store.dispatch("fetchTenantId");
        await this.fetchData();
    },
    methods: {
        async reorderDependentField(index, pos) {
            const swapwith = index + pos;

            const fieldSwap = this.orderedDependentFields[index];
            const fieldSwapWith = this.orderedDependentFields[swapwith];
            const orderSwap = fieldSwap.order;
            const orderSwapWith = fieldSwapWith.order;
            this.$set(fieldSwap, "order", orderSwapWith);
            this.$set(fieldSwapWith, "order", orderSwap);

            await this.silentUpdateDependents();
        },
        async fetchData() {
            let field;
            this.currentField = null;
            let query = this.$route;
            this.loading = true;
            // edited
            if (query.params.id) {
                this.showDeleteIcon = true;
                this.error = this.fieldData = null;
                let field_data = await this.fetchFieldData(query.params.id);
                this.fieldIsCascaded = this.showCascadedField = field_data.is_cascaded;
                field = this.fields.find((ele) => ele.name === field_data.type);
                this.currentField = field;
                if (field && field.has_options) {
                    this.optionsLoading = true;
                    let options = await this.fetchFieldsOptions(field_data.id);
                    this.fieldOptions = options.map((option) => {
                        return {
                            ...option,
                            createdDefault: true,
                            edited: false,
                            showDependentField: false,
                        };
                    });
                }

                this.fieldData = { ...field_data, href: field_data.meta, use_in_gap_validation: field_data && field_data.meta && JSON.parse(field_data.meta)?.use_in_gap_validation };
            }
            // added new
            else if (query.query.field) {
                this.showDeleteIcon = false;
                this.showCascadedField = true;
                field = this.fields.find((ele) => ele.id === query.query.field);
                this.currentField = field;
                this.fieldData = {
                    ...this.fieldData,
                    field_type: field.id,
                    ui_label: field.ui_label,
                    type: field.name,
                    tenant_id: this.$store.getters.getTenantId,
                    is_cascaded: field.has_options,
                    added: true,
                    saved: false,
                };
                if (field?.has_options) {
                    let options = await this.fetchFieldsOptions(field.id);
                    this.fieldOptions = options.map((option) => {
                        return {
                            ...option,
                            createdDefault: true,
                            edited: false,
                        };
                    });
                    //   this.optionsLoading = false;
                }
            }
            // this.fieldTypeIcon = this.getIcon(field.name);
            this.loading = false;
            this.optionsLoading = false;
        },
        async handleSaveAndCreateField() {
            let { name, label, placeholder } = this.fieldData;
            let query = this.$route;
            if (!name || !label || (!placeholder && this.showLabelAsRichText(this.fieldData))) {
                this.$toast.error("Please fill all mandatory fields");
            } else {
                if (query.query.field && query.name === "Add Field") {
                    await this.createField();
                } else {
                    this.isEditLoading = true;
                    await this.saveData();
                    this.isEditLoading = false;
                }
            }
        },
        async fetchFieldsList() {
            this.isLoading = true;
            let payload = {
                tenant_id: this.$store.getters.getTenantId,
                req_offset: 0,
                req_limit: 1,
            };
            let url = `/fields/${this.$store.getters.getTenantId}/all`;
            let result = await axios.get(url, payload);
            this.isLoading = false;
            return result.data.fields;
        },
        async createField() {
            let query = this.$route;
            let field = this.fields.find((ele) => ele.id === query.query.field);

            let { name, description, placeholder, label, field_type, tenant_id, href, use_in_gap_validation } = this.fieldData;
            let post_data = {};
            if (field.name === "Linked button") {
                post_data = { name, description, placeholder, label, field_type, tenant_id, href, is_cascaded: false, options: [] };
            } else if (field.name === "Date Range (Timeline)") {
                post_data = { name, description, placeholder, label, field_type, tenant_id, use_in_gap_validation, is_cascaded: false, options: [] };
            } else {
                post_data = { name, description, placeholder, label, field_type, tenant_id, is_cascaded: false, options: [] };
            }
            if (field?.has_options) {
                let fieldoptions_data = this.fieldOptions;
                let FieldsOptionsList = [];
                fieldoptions_data.map((el) => {
                    // let dependent_fields = el.dependent_fields;
                    // let new_dependednt_fields = [];
                    // dependent_fields.map((ele) =>
                    //   new_dependednt_fields.push(`${ele.id}`)
                    // );
                    FieldsOptionsList.push({
                        label: el.label,
                        value: el.value,
                        order: el.order,
                        is_default: el.is_default,
                        dependent_fields: el?.dependents ? el?.dependents?.map((fld) => fld.id) : [],
                    });
                });

                let { errorCount, isCascaded } = this.ValidateOptions();
                if (errorCount > 0 || !FieldsOptionsList.length) {
                    return this.$toast.error("Atleast one option is required");
                }
                post_data = {
                    ...post_data,
                    options: FieldsOptionsList,
                    is_cascaded: isCascaded.cascaded > 0 ? true : false,
                };
            }
            post_data.description = post_data.description ? post_data.description : null;
            let url = `/fields`;
            try {
                let { data } = await axios.post(url, post_data);
                if (data) {
                    this.$toast.success(data.message);
                    if (data?.field_id) {
                        this.$router
                            .push({
                                name: "Field Setting",
                                params: { id: data.field_id },
                            })
                            .then(async () => {
                                await this.fetchData();
                            });
                    }
                }
            } catch (err) {
                this.$toast.error(err.response.data.detail || "failed to create field");
            }
        },

        async fetchFieldData(fieldId) {
            let url = `/fields/${this.$store.getters.getTenantId}/field/${fieldId}`;
            let result = await axios.get(url);
            return result.data;
        },

        handleSaveOptions() {
            let { errorCount } = this.ValidateOptions();
            if (errorCount > 0) {
                this.$toast.error("please fill all mandatory fields");
            }
        },
        ValidateOptions() {
            this.errorCount = 0;
            let cascadedData = {
                cascaded: 0,
                notCascaded: 0,
            };
            let data = this.fieldOptions.map((el) => {
                let errors = {
                    label: false,
                    value: false,
                };
                if (el.dependent_fields && el.dependent_fields.length) {
                    cascadedData.cascaded += 1;
                } else {
                    cascadedData.notCascaded += 1;
                }
                if (!el.label.length) {
                    errors.label = true;
                    this.errorCount += 1;
                }
                if (!el.value.length) {
                    errors.value = true;
                    this.errorCount += 1;
                }
                return { ...el, errors: errors };
            });
            this.fieldOptions = data;
            return { errorCount: this.errorCount, isCascaded: cascadedData };
        },

        async handleAddOptions(index) {
            let fieldId = this.$route.params.id;
            let { errorCount } = this.ValidateOptions();
            if (errorCount > 0) {
                this.$toast.error("Please fill all mandatory fields");
            } else {
                let data = {
                    // for dummy content onl
                    id: Math.round(Math.random() * 1000000000),
                    // id: fieldId,
                    label: "",
                    value: "",
                    added: true,
                    edited: false,
                    is_default: false,
                    order: this.fieldOptions.length + 1 || 1,
                    dependent_fields: [],
                };
                if (index != 0 && fieldId) {
                    let fieldOption = this.fieldOptions[index] ?? null;
                    if (!fieldOption.createdDefault) {
                        try {
                            let { data } = await this.hanleAddOptionsApi(fieldId, fieldOption);
                            this.fieldOptions[index].added = false;
                            this.fieldOptions[index].id = data.option_id;
                            this.fieldOptions[index].createdDefault = true;
                            this.$toast.success(data.message);
                        } catch (error) {
                            this.fieldOptions[index].added = true;
                            this.fieldOptions[index].errors = { label: true, value: true };
                            this.fieldOptions[index].label = "";
                            this.fieldOptions[index].value = "";
                            return this.$toast.error(error.response.data.detail || "Error while Creating Options");
                        }
                    }
                }
                this.fieldOptions.push(data);
            }
        },
        async saveData() {
            let field_data = this.fieldData;
            let fieldId = this.$route.params.id;
            let post_data = {
                tenant_id: field_data.tenant_id,
                field_id: fieldId,
                label: field_data.label,
                name: field_data.name,
                description: field_data.description ? field_data.description : null,
                placeholder: field_data.placeholder,
                href: field_data.href,
                use_in_gap_validation: field_data.use_in_gap_validation ? field_data.use_in_gap_validation : false,
            };
            let field = this.fields.find((ele) => ele.name === field_data.type);
            if (field.has_options) {
                let fieldoptions_data = this.fieldOptions;
                let FieldsOptionsList = [];
                fieldoptions_data.map((el) => {
                    let dependent_fields = el.dependent_fields || [];
                    let new_dependednt_fields = [];
                    dependent_fields?.map((ele) => new_dependednt_fields.push(`${ele.id}`));
                    FieldsOptionsList.push({
                        id: el.id,
                        label: el.label,
                        value: el.value,
                        edited: el.edited,
                        added: el.added,
                        dependent_fields: new_dependednt_fields,
                    });
                });

                let { errorCount } = this.ValidateOptions();

                if (errorCount > 0 || !FieldsOptionsList.length) {
                    return this.$toast.error("All fields are mandatory");
                } else if (!FieldsOptionsList.length) {
                    return this.$toast.error("Atleast one option is required");
                }

                FieldsOptionsList.map(async (optionData) => {
                    if (optionData.edited) {
                        try {
                            let response = await this.hanleUpdateOptionsApi(fieldId, optionData);
                            this.$toast.success(response.data.message);
                            this.isEditLoading = false;
                        } catch (err) {
                            this.$toast.error(err.response.data.detail);
                            this.isEditLoading = false;
                        }
                    }
                    if (optionData.added) {
                        try {
                            let response = await this.hanleAddOptionsApi(fieldId, optionData);
                            this.$toast.success(response.data.message);
                            this.isEditLoading = false;
                        } catch (err) {
                            this.$toast.error(err.response.data.detail);
                            this.isEditLoading = false;
                        }
                    }
                });
            }
            let url = `/fields`;
            try {
                let { data } = await axios.put(url, post_data);
                this.$toast.success(data.message);
            } catch (error) {
                this.$toast.error(error.response.data.detail);
            }
        },

        async hanleUpdateOptionsApi(field_id, option, isDefault = false, reOrder = false) {
            let url = `/fields/${field_id}/options/${option.id}`;
            let orderList = [];
            this.fieldOptions.forEach((el, i) => {
                if (el.createdDefault) {
                    let obj = {};
                    obj[el.id] = i;
                    orderList.push(obj);
                }
            });
            let post_data = {
                option_value: option.value,
                option_label: option.label,
                is_default: reOrder || !isDefault ? option.is_default : !option.is_default,
                order: option.order,
                order_list: orderList,
            };
            try {
                let result = await axios.put(url, post_data);
                if (isDefault) {
                    this.fieldOptions.map((el) => {
                        if (el.id === option.id) {
                            el.is_default = !option.is_default;
                        } else {
                            el.is_default = false;
                        }
                    });
                }
                return result;
            } catch (error) {
                this.$toast.error(error.response.data.detail);
            }
        },

        async hanleAddOptionsApi(field_id, option) {
            let url = `/fields/${field_id}/options`;
            let post_data = {
                option_value: option.value,
                option_label: option.label,
                is_default: option.is_default,
                order: option.order,
                dependent_field_id: option.dependents || [],
            };
            let result = await axios.post(url, post_data);
            let fieloptionsList = this.fieldOptions.map((el) => {
                if (el.id === option.id) {
                    return { ...el, added: false };
                } else {
                    return { ...el };
                }
            });
            this.fieldOptions = fieloptionsList;
            this.$forceUpdate();
            return result;
        },

        // Handle Update Options
        async handleOptionUpdate(index, isDefault = false) {
            let option = this.fieldOptions[index];
            if (option.added) {
                this.fieldOptions.map((el) => {
                    if (el.id === option.id) {
                        el.is_default = !el.is_default;
                    } else {
                        el.is_default = false;
                    }
                });
            } else {
                if (!option.label && !option.value) {
                    this.$toast.error("please fill mandatory fields");
                } else {
                    const resp = await this.hanleUpdateOptionsApi(this.$route.params.id, option, isDefault);
                    this.fieldOptions[index].edited = false;
                    this.$toast.success(resp.data.message || "Options updated!");
                }
            }
        },
        // End Handle Options Update

        // Handle Reorder Options

        handleReorderOptions(index, orderType) {
            let option = this.fieldOptions[index];

            if (!option.label && !option.value) {
                this.$toast.error("please fill mandatory fields");
            } else {
                if (orderType === "up") {
                    if (index !== 0) this.swapOptions(index, index - 1);
                } else if (orderType === "down") {
                    if (index !== this.fieldOptions.length - 1) this.swapOptions(index, index + 1);
                }
            }
        },
        async swapOptions(swapElement, swapWith) {
            let field_options = this.fieldOptions;
            let temp = null;
            temp = field_options[swapWith];
            field_options[swapWith] = field_options[swapElement];
            field_options[swapElement] = temp;
            this.fieldOptions = field_options;
            let option = this.fieldOptions[swapWith];
            let reOrder = true;
            let isDefault = false;
            try {
                const resp = await this.hanleUpdateOptionsApi(this.$route.params.id, option, isDefault, reOrder);
                if (resp.data) this.$toast.success("option reordered!");
            } catch (error) {
                this.$toast.success(error.response.data.detail || "failed to reorder");
            }
            this.$forceUpdate();
        },

        // End reorder options

        appendHandleEvent(index) {
            let labelvalue = this.fieldOptions[index].label;
            return (this.fieldOptions[index].value = labelvalue);
        },
        async handleEditOption(index) {
            this.fieldOptions[index].edited = !this.fieldOptions[index].edited;
        },
        async showDependentFields(index) {
            this.$refs["depModal"].showModal();
            this.fieldLists = await this.fetchFieldsList();

            this.activeOptionInMutation = index;
            this.dependentFields = this.getDependentFields;
        },

        async storeDependents(field) {
            const order = this.dependentFields?.length ? Math.max(...this.dependentFields.map((e) => e?.order ?? 0)) + 10 : 10;
            const newDependent = {
                ...field,
                mandatory: false,
                order: order,
            };
            this.$nextTick(async () => {
                this.dependentFields = this.dependentFields.map((e) => (e.id === newDependent.id ? newDependent : e));
                let addDependendt = true;
                await this.silentUpdateDependents(addDependendt, field);
            });
        },

        async deselectDependent(dependentField) {
            const promise = await this.$refs["confirm-popup"].show({
                title: "Are you sure?",
                message: "This Dependent field will be deleted. Please confirm to continue deleting this dependent field.",
            });
            if (promise) await this.UnassignDependent(dependentField, true);
            else return;
        },
        async UnassignDependent(dependentField, silent = false) {
            const promise =
                silent ||
                (await this.$refs["confirm-popup"].show({
                    title: "Are you sure?",
                    message: "This Dependent field will be deleted. Please confirm to continue deleting this dependent field.",
                }));
            if (promise) {
                if (this.getSelectedField) {
                    const optionId = this.getSelectedField.id;
                    try {
                        this.dependentFields = this.dependentFields.filter((e) => e.id !== dependentField.id);
                        const fieldId = this.$route.params.id;
                        await axios.delete(`/fields/${fieldId}/options/${optionId}/dependents/${dependentField.id}`);

                        this.fieldOptions[this.activeOptionInMutation].dependents = this.fieldOptions[this.activeOptionInMutation].dependents.filter((e) => e.id !== dependentField.id);
                    } catch (error) {
                        console.log("error in deleting dependent field.", error);
                    }
                } else return;
            }

            this.$refs["confirm-popup"].close();
        },

        async setDependentMandatory(field, e) {
            field.mandatory = e.target.checked;
            await this.silentUpdateDependents();
        },

        async saveDependents() {
            const fieldId = this.$route.params.id;
            var optionId = this.getSelectedField?.id;
            if (fieldId && optionId) {
                var dependents = [];
                this.dependentFields.forEach((e) => {
                    var o = { ...e, mandatory: e.mandatory, order: e.order };
                    dependents.push({
                        id: o.id,
                        order: o.order,
                        mandatory: o.mandatory,
                    });
                });

                try {
                    let { data } = await axios.put(`/fields/${fieldId}/options/${optionId}/dependents`, { dependents });
                    this.$toast.success(data.message || "Dependency modified successfully");
                    this.fieldOptions[this.activeOptionInMutation].dependents = this.dependentFields;
                    this.fieldData.saved = true;
                    this.closeDependentFields();
                } catch (error) {
                    console.log(error);
                }
            }
        },

        async createDependent(option, field, silent = false) {
            try {
                let dependent_field = option?.dependents?.find((el) => el.id === field.id);
                let dependentoption = { order: dependent_field.order + 20 || option.dependents.length + 20, mandatory: dependent_field.mandatory, control_field_value: option.option_value, dependent_field_id: dependent_field.id };
                let field_id = this.$route.params.id;
                const response = await axios.post(`/fields/${field_id}/options/${option.id}/dependents`, dependentoption);
                if (response && response.data && !silent) {
                    this.$toast.success(response.data.message);
                }
            } catch (error) {
                this.$toast.error(error.message || "Cant update depdendents");
            }
        },

        async updateDependents(option, silent = false) {
            try {
                let field_id = this.$route.params.id;
                const response = await axios.put(`/fields/${field_id}/options/${option.id}/dependents`, {
                    dependents: option.dependents.map((e) => {
                        return { id: e.id, order: e.order, mandatory: e.mandatory };
                    }),
                });
                if (response && response.data && !silent) {
                    this.$toast.success(response.data.message);
                }
            } catch (error) {
                this.$toast.error(error.message || "Cant update depdendents");
            }
        },

        async silentUpdateDependents(addDependent = false, field) {
            const changedOption = this.getSelectedField;
            changedOption.dependents = this.dependentFields;
            if (addDependent) {
                await this.createDependent(changedOption, field, true);
            } else {
                await this.updateDependents(changedOption, true);
            }
        },

        async closeDependentFields() {
            let field = this.getSelectedField;
            field.dependents = this.dependentFields;
            this.fieldOptions = this.fieldOptions.map((fld, idx) => (idx === this.activeOptionInMutation ? field : fld));
            this.$refs["depModal"].hideModal();
            !this.fieldData.saved && (await this.updateDependents(field));
            this.dependentFields = [];
            this.fieldData.saved = false;
            this.$forceUpdate();
        },

        async handleDeleteOptions(option, field_data) {
            const promise = await this.$refs["confirm-popup"].show({
                title: "Are you sure?",
                message: "This field option  will be deleted permanently. Please confirm to continue deleting this field option.",
            });

            if (promise) {
                let option_id = option.id;
                let fieldId = field_data.id;
                let created_default_length = 0;
                this.fieldOptions.map((el) => {
                    if (el.createdDefault) {
                        created_default_length += 1;
                    }
                });
                if (created_default_length < 2 && !option.added) {
                    this.$refs["confirm-popup"].close();
                    return this.$toast.error("Atleast one option is required");
                }
                if (option.createdDefault) {
                    let url = `/fields/${fieldId}/options/${option_id}`;
                    try {
                        let result = await axios.delete(url);
                        this.$toast.success(result.data.message);
                        if (result) {
                            let optionsData = this.fieldOptions.filter((el) => el.id !== option_id);
                            this.$refs["confirm-popup"].close();
                            return (this.fieldOptions = optionsData);
                        }
                    } catch (error) {
                        this.$refs["confirm-popup"].close();
                        this.$toast.error(error.response.data.detail);
                    }
                } else {
                    let optionsData = this.fieldOptions.filter((el) => el.id !== option_id);
                    this.$refs["confirm-popup"].close();
                    return (this.fieldOptions = optionsData);
                }
            }
            this.$refs["confirm-popup"].close();
        },
        tippyName() {
            return `${uuid.v4()}`;
        },
        getIcon(field_name) {
            return this.fields.find((ele) => ele.name === field_name)?.icon || "";
        },
        async fetchFieldsOptions(fieldId) {
            let result = await axios.get(`/fields/${fieldId}/options`);
            let formatedResult = [];
            let dependent_fields = (dep_fel) => {
                return dep_fel.map((el) => {
                    // let val = Object.keys(el)[0];
                    return {
                        ...el,
                        id: el.field_id,
                    };
                });
            };
            result.data.options.map((ele) => {
                formatedResult.push({
                    ...ele,
                    id: ele.id,
                    label: ele.option_label,
                    value: ele.option_value,
                    // dependents: ele.dependents,
                    dependents: dependent_fields(ele.dependents),
                });
            });
            formatedResult.sort((a, b) => a.order - b.order);
            return formatedResult;
        },
        closeDeleteFieldModal() {
            this.deleteFieldModalOpen = false;
        },
        async handleDeleteField() {
            this.deleteFieldModalOpen = true;

            this.usedInDetails = [
                { label: "Blocks", details: [], count: 10, key: "blocks", route: "block-admin-details", key_count: "blocks_count" },
                { label: "Checks", details: [], count: 12, key: "checks", route: "check admin details", key_count: "checks_count" },
                { label: "Form", details: [], count: 34, key: "blocks", route: "form admin details", key_count: "forms_count" },
                { label: "Package", details: [], count: 0, key: "packages", route: "Screening Package View", routeParams: "packageName", key_count: "package_count" },
            ];
            this.usedInDetails = this.usedInDetails.map((el) => ({
                ...el,
                count: this.fieldData[el.key_count] || 0,
                details:
                    this.fieldData[el.key]?.map((fld) => ({
                        id: Object.entries(fld)[0][0],
                        name: Object.entries(fld)[0][1],
                        key: el.key,
                        route: el.route,
                        routeParams: el.routeParams || "id",
                    })) || [],
            }));
            // const promise = await this.$refs["confirm-popup"].show({
            //     title: "Are you sure?",
            //     message:
            //         "This field will be deleted permanently. Please confirm to continue deleting this field.",
            // });
            // // return
            // if (promise) {
            //     let url = `/fields/${field.id}`;
            //     try {
            //         let result = await axios.delete(url);
            //         this.$router.push({ path: "/field-admin" });
            //         this.$toast.success(result.data.message);
            //     } catch (error) {
            //         this.$toast.error(error.response.data.detail);
            //     }
            // }
            // this.$refs["confirm-popup"].close();
        },
        async handleDelete(field) {
            let url = `/fields/${field.id}`;
            this.isDeleteLoading = true;
            try {
                let result = await axios.delete(url);
                this.$router.push({ path: "/field-admin" });
                this.$toast.success(result.data.message);
                this.closeDeleteFieldModal();
            } catch (error) {
                this.$toast.error(error.response.data.detail);
            }
            this.isDeleteLoading = false;
        },
        showLabelAsRichText(fieldType) {
            if (fieldType.type === "Candidate Consent" || fieldType.type === "Candidate Optional Consent" || fieldType.type === 'label info rich text') return true;
            else false;
        },
        showDeleteIconWhen(field) {
            // fields with checks can also be deleted now as per DEL-1082
            return field.is_custom;
        },
    },
};
